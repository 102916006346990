import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "8a2a18c3-7788-4931-98ec-7517d4719fa9",
    videoLibraryId: "181305",
    streamCdnUrl: "https://vz-3d752cc8-c82.b-cdn.net",
    streamApiKey: "f875b888-f0e7-4738-9355654990e6-d782-47c6",
    storageApiPassword: "eef52cd9-2ebd-444c-9c9c856816b0-40b0-4be0",
    cdnUrl: "https://maxonjukes.metaluxe.com.au",
    devFolder: "/max-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4k_dashboard.mp4",
    assetDashboardMp4: "theme/dashboard/4k_asset_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'Welcome',
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],

    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: ['Max On Jukes'],
        panoramasUrl: ['https://storage.net-fs.com/hosting/7566552/8/'],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

});

export default { theme };
